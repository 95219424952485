import React, { useEffect, useRef, useState } from "react";
import { LinkContainer } from "./LinkContainer";

const AnnouncementBar = ({ annoucementbar }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const timeoutRef = useRef();
  const delay = 4000;

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setCurrentSlide((prevIndex) =>
          prevIndex === annoucementbar.announcements.length - 1
            ? 0
            : prevIndex + 1
        ),
      delay
    );
    return () => {
      resetTimeout();
    };
  }, [currentSlide]);
  return (
    <div
      className="fixed -top-[2px] left-0 w-full z-[9999]"
      style={{
        backgroundColor: annoucementbar.bgColor?.hex,
        color: annoucementbar.textColor?.hex,
      }}
    >
      <div
        className={`whitespace-nowrap w-full transition-all duration-500 ease-linear h-[51px]`}
        // style={{ transform: `translate3d(${-currentSlide * 100}%, 0, 0)` }}
      >
        {/* {data?.allSanityPromobar?.nodes.map((item, index) => ( */}
        <LinkContainer
          linkType={annoucementbar.announcements[currentSlide].linkType}
          link={annoucementbar.announcements[currentSlide].link}
          cls="inline-block w-full h-[51px] text-white no-underline"
        >
          <p className="w-full h-full flex items-center justify-center text-inherit text-center font-dinnext font-light">
            {annoucementbar.announcements[currentSlide].announcementText}
          </p>
        </LinkContainer>
        {/* ))} */}
      </div>
    </div>
  );
};

export default AnnouncementBar;
